import { Stack } from "@mui/material";
import { Patient } from "../../services/types/patient.type";
import LayoutSideMenu from "../../components/layout-side-menu";
import LayoutMainContent from "../../components/layout-main-content";
import { usePatientInfo } from "../../services/hooks/patient-info/use-patient-info.hook";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePracticeData } from "../../services/hooks/patient-practice/use-practice-data.hook";
import CircularProgress from "@mui/material/CircularProgress";

interface PatientFormsDashboardProps {
  submittedForms: string[];
}

export default function PatientFormsDashboard({
  submittedForms,
}: PatientFormsDashboardProps) {
  const navigate = useNavigate();
  const { practiceName = "" } = useParams();
  const { data: patientData, isError: patientHasError } = usePatientInfo();
  const patient = patientData?.portal_patient as Patient;
  const { data: practiceData, isError: isPracticeNameError } =
    usePracticeData(practiceName);

  useEffect(() => {
    if (isPracticeNameError || patientHasError) {
      navigate("/");
    }
  }, [isPracticeNameError, patientHasError, navigate]);

  return (
    <Stack direction="row" width="100vw">
      <LayoutSideMenu practice={practiceData} />
      {patientData && practiceData ? (
        <LayoutMainContent
          patient={patient}
          practice={practiceData}
          submittedForms={submittedForms}
        />
      ) : (
        <CircularProgress sx={{ margin: "auto" }} />
      )}
    </Stack>
  );
}

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type SideMenuItemContentProps = {
  isActive: boolean;
  primary: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
};

export default function SideMenuItemContent({
  isActive,
  primary,
  Icon,
}: SideMenuItemContentProps) {
  const theme = useTheme();

  return (
    <>
      <ListItemIcon
        sx={{
          minWidth: 0,
          justifyContent: "center",
        }}
      >
        <Icon
          sx={{
            color: isActive
              ? theme.palette.common.white
              : theme.palette.neutral[500],
          }}
        />
      </ListItemIcon>

      <ListItemText
        primary={primary}
        primaryTypographyProps={{
          variant: "body2",
          fontWeight: isActive ? "fontWeightBold" : "fontWeightRegular",
        }}
        sx={{
          my: 0,
          whiteSpace: "nowrap",
          textDecoration: "none",
          color: isActive
            ? theme.palette.common.white
            : theme.palette.neutral[500],
        }}
      />
    </>
  );
}

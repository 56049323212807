import { createTheme } from "@mui/material";
import type { ColorPartial } from "@mui/material/styles/createPalette";
import type { Shadows } from "@mui/material/styles/shadows";
import { primary, neutral, success, error } from "./colors";
import sunoShadows from "./shadows";
import sunoBreakpoints from "./breakpoints";
import { getSunoComponents } from "./components";
import getSunoTypography from "./typography";

let sunoTheme = createTheme();

sunoTheme = createTheme(sunoTheme, {
  breakpoints: sunoBreakpoints,
  palette: {
    neutral,
    primary: {
      main: primary[400],
      dark: primary[700],
      light: primary[50],
    },
    success: {
      main: success[600],
      light: success[500],
    },
    error: {
      main: error[600],
      light: error[500],
    },
    sideMenu: {
      main: "#2E1832",
      itemBgcolorSelected: "#C79AEB",
      itemBgcolor: "#FFFFFF",
    },
    avatar: {
      bgcolor: "rgba(0, 0, 0, 0.5)",
    },
    lightBackground: "#F9FAFF",
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    divider: neutral[300],
    text: {
      primary: neutral[900],
      secondary: "#292833",
    },
  },
  shadows: {
    ...(Array(25).fill("none") as Shadows),
    ...sunoShadows,
  },
  typography: getSunoTypography(sunoTheme),
  components: getSunoComponents(sunoTheme),
});

export { sunoTheme };

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }

  interface Palette {
    sideMenu: {
      main: string;
      itemBgcolorSelected: string;
      itemBgcolor: string;
      colorSelected: string;
    };
    neutral: ColorPartial;
    avatar: {
      bgcolor: string;
    };
    lightBackground: string;
  }

  interface PaletteOptions extends Palette {}

  interface Shadows {
    search: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    borderless: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
    subtitle2: false;
    h6: false;
  }
}

import { Theme } from "@mui/material/styles";

export const getTypographyComponents = (sunoTheme: Theme) => ({
  MuiTypography: {
    styleOverrides: {
      root: {
        "&.MuiListItemText-primary": {
          fontSize: sunoTheme.typography.pxToRem(16),
          lineHeight: sunoTheme.typography.pxToRem(24),
          letterSpacing: 0,
        },
        "&.MuiListItemText-secondary": {
          fontSize: sunoTheme.typography.pxToRem(14),
          lineHeight: sunoTheme.typography.pxToRem(17),
          letterSpacing: 0,
        },
      },
    },
  },
});

import { common } from "@mui/material/colors";
import { primary, neutral } from "../colors";
import { Theme } from "@mui/material/styles";

export const getButtonsComponents = (sunoTheme: Theme) => ({
  MuiButton: {
    variants: [
      {
        props: { variant: "borderless" },
        style: {
          color: primary[500],
          textTransform: "none",
          "&:hover": {
            color: primary[400],
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        "&.MuiButton-contained": {
          color: common.white,
          background: primary[500],
          borderRadius: "8px",
          textTransform: "none",
          "&:hover": {
            background: primary[400],
          },
          "&:disabled": {
            background: neutral[300],
            color: neutral[700],
          },
        },
        "&.MuiButton-outlined": {
          color: primary[500],
          borderColor: primary[500],
          borderRadius: "8px",
          textTransform: "none",
          background: "transparent",
          "&:hover": {
            color: primary[400],
            borderColor: primary[400],
            background: "transparent",
          },
          "&:disabled": {
            color: neutral[600],
            borderColor: primary[400],
          },
        },
        "&.MuiButton-sizeSmall": {
          padding: `${sunoTheme.spacing(1)} ${sunoTheme.spacing(2)}`,
        },
        "&.MuiButton-sizeMedium": {
          padding: `${sunoTheme.spacing(1.5)} ${sunoTheme.spacing(2.5)}`,
        },
        "&.MuiButton-sizeLarge": {
          padding: sunoTheme.spacing(2),
          width: "100%",
          maxWidth: 436,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: primary[700],
        "&:hover": {
          color: primary[500],
        },
      },
    },
  },
});

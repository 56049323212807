import useFetch from "../../../utils/fetch-data";
import { useSessionStorage } from "../../../utils/local-storage";

const INTAKE_FORM_PATH = `/patient-portal/me/intake-form-requests/`;

const params = {
  ordering: "-created_at",
  status: "PENDING,SUBMITTED",
};

export function useIntakeFormSubmissions() {
  const localAuthSession = useSessionStorage("authToken");

  const {
    isLoading,
    data = [],
    isError,
  } = useFetch({
    method: "GET",
    url: `${process.env.REACT_APP_SUNO_API}${INTAKE_FORM_PATH}`,
    token: localAuthSession.getData(null),
    params: params,
  });

  return {
    data,
    isLoading,
    isError,
  };
}

import { useEffect, useState } from "react";
import callBackend, { BackendRequest } from "../call-backend";

export default function useFetch<T, K>({
  url,
  method,
  body,
  params,
  token,
  skip,
}: BackendRequest<T> & { skip?: boolean }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [data, setData] = useState<K>();

  useEffect(() => {
    if (skip) {
      setData(undefined);
      setIsError(false);
      setIsLoading(false);
      return;
    }
    callBackend({ url, method, body, token, params }).then(
      ({ data, isError }) => {
        setData(data as any);
        setIsError(isError);
        setIsLoading(false);
      },
    );
  }, [body, method, token, url, skip, params]);

  return {
    data,
    isLoading,
    isError,
  };
}

import { Theme } from "@mui/material/styles";
import { getButtonsComponents } from "./buttons";
import { getInputComponents } from "./input";
import { getCheckboxComponents } from "./checkbox";
import { getTypographyComponents } from "./typography";

export const getSunoComponents = (sunoTheme: Theme) => ({
  ...getCheckboxComponents(),
  ...getButtonsComponents(sunoTheme),
  ...getInputComponents(sunoTheme),
  ...getTypographyComponents(sunoTheme),
});

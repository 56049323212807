import { useCallback, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import callBackend from "../../../utils/call-backend";
import { useSessionStorage } from "../../../utils/local-storage";

export default function useAuthentication() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const localAuthSession = useSessionStorage("authToken");

  const [authenticated, setAuthenticated] = useState(
    Boolean(localAuthSession.getData(null)),
  );

  const auth = useCallback(
    ({
      email,
      password,
      practice_id,
    }: {
      email: string;
      password: string;
      practice_id: number;
    }) => {
      datadogRum.setGlobalContextProperty("email", email);
      datadogRum.setGlobalContextProperty("practice_id", practice_id);
      datadogRum.setGlobalContextProperty("dob", password);

      setIsLoading(true);
      const auth_token = localAuthSession.getData(null);
      if (auth_token) {
        return Promise.resolve({ data: { auth_token }, isError: false });
      }
      return callBackend<
        { email: string; dob: string; practice_id: number },
        { auth_token: string }
      >({
        url: `${process.env.REACT_APP_SUNO_API}/auth/token/login/`,
        method: "POST",
        body: {
          email: email,
          dob: password,
          practice_id,
        },
      })
        .then(({ data, isError }) => {
          if (!isError && data.auth_token) {
            setAuthenticated(true);
            localAuthSession.updateData(data?.auth_token);
          }
          setIsError(isError);

          return { data, isError };
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [localAuthSession],
  );

  return {
    auth,
    isError,
    isLoading,
    authenticated,
  };
}

import { Box, Container, Stack, styled, Typography } from "@mui/material";
import FormDashboardPreview from "../form-dashboard-preview";
import {
  IntakeFormRequestSubmission,
  IntakeFormSubmissionStatus,
} from "../../services/types/intake-form-request.type";
import { grey } from "@mui/material/colors";
import { useMemo } from "react";
import { PatientPracticeType } from "../../services/types/patient-practice.type";

interface MainContentFormsProps {
  requestId: number;
  fullName: string;
  email?: string;
  practice: PatientPracticeType | undefined;
  intakeFormRequests: IntakeFormRequestSubmission[] | undefined;
}

export default function MainContentForms(props: MainContentFormsProps) {
  const {
    intakeFormRequests = [],
    requestId,
    email,
    fullName,
    practice,
  } = props;

  const { submittedForms, incompleteForms } = useMemo(() => {
    const submittedForms: IntakeFormRequestSubmission[] = [];
    const incompleteForms: IntakeFormRequestSubmission[] = [];

    intakeFormRequests.forEach((formRequest) =>
      formRequest.status === IntakeFormSubmissionStatus.SUBMITTED
        ? submittedForms.push(formRequest)
        : incompleteForms.push(formRequest),
    );

    return { submittedForms, incompleteForms };
  }, [intakeFormRequests]);

  return (
    <Container>
      <Stack direction="row" flexWrap="wrap">
        {!!submittedForms.length && (
          <>
            <Typography fontWeight="bold">Completed Forms: </Typography>
            <IntakeFormsWrapper>
              {submittedForms.map((formRequest) => (
                <FormDashboardPreview
                  key={formRequest.id}
                  email={email}
                  fullName={fullName}
                  practiceName={practice?.name}
                  requestId={requestId}
                  intakeForm={formRequest.intake_form}
                  isSubmitted={
                    formRequest.status === IntakeFormSubmissionStatus.SUBMITTED
                  }
                />
              ))}
            </IntakeFormsWrapper>
          </>
        )}
        {!!incompleteForms.length && (
          <>
            <Typography fontWeight="bold">Pending Forms: </Typography>
            <IntakeFormsWrapper>
              {incompleteForms.map((formRequest) => (
                <FormDashboardPreview
                  key={formRequest.id}
                  email={email}
                  fullName={fullName}
                  practiceName={practice?.name}
                  requestId={requestId}
                  intakeForm={formRequest.intake_form}
                  isSubmitted={
                    formRequest.status === IntakeFormSubmissionStatus.SUBMITTED
                  }
                />
              ))}
            </IntakeFormsWrapper>
          </>
        )}
      </Stack>
    </Container>
  );
}

const IntakeFormsWrapper = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  borderRadius: "5px",
  gap: 16,
  padding: "16px 0",
  backgroundColor: grey[50],
  flexWrap: "wrap",
  "&:not(:last-of-type)": {
    marginBottom: 8,
  },
}));

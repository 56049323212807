import { Theme } from "@mui/material";

const getSunoTypography = (sunoTheme: Theme) => ({
  fontFamily: "LatoRegular, sans-serif",
  fontSize: sunoTheme.typography.pxToRem(16),
  fontWeightSemiBold: 600,
  fontWeightExtraBold: 700,
  h1: {
    fontFamily: "LatoRegular, sans-serif",
    fontWeight: 600,
    fontSize: sunoTheme.typography.pxToRem(36),
    lineHeight: sunoTheme.typography.pxToRem(42),
    letterSpacing: sunoTheme.typography.pxToRem(-0.32),
  },
  h2: {
    fontFamily: "LatoRegular, sans-serif",
    fontWeight: 600,
    fontSize: sunoTheme.typography.pxToRem(33),
    lineHeight: sunoTheme.typography.pxToRem(40),
    letterSpacing: sunoTheme.typography.pxToRem(-0.32),
  },
  h3: {
    fontFamily: "LatoRegular, sans-serif",
    fontWeight: 600,
    fontSize: sunoTheme.typography.pxToRem(28),
    lineHeight: sunoTheme.typography.pxToRem(34),
    letterSpacing: sunoTheme.typography.pxToRem(-0.32),
  },
  h4: {
    fontFamily: "LatoRegular, sans-serif",
    fontWeight: 600,
    fontSize: sunoTheme.typography.pxToRem(28),
    lineHeight: sunoTheme.typography.pxToRem(34),
    letterSpacing: sunoTheme.typography.pxToRem(-0.32),
  },
  h5: {
    fontFamily: "LatoRegular, sans-serif",
    fontWeight: 600,
    fontSize: sunoTheme.typography.pxToRem(24),
    lineHeight: sunoTheme.typography.pxToRem(30),
    letterSpacing: sunoTheme.typography.pxToRem(-0.32),
  },
  subtitle1: {
    fontFamily: "LatoRegular, sans-serif",
    fontSize: sunoTheme.typography.pxToRem(20),
    lineHeight: sunoTheme.typography.pxToRem(24),
  },
  body1: {
    fontFamily: "LatoRegular, sans-serif",
    fontSize: sunoTheme.typography.pxToRem(14),
    lineHeight: sunoTheme.typography.pxToRem(17),
    letterSpacing: 0,
  },
  body2: {
    fontFamily: "LatoRegular, sans-serif",
    fontSize: sunoTheme.typography.pxToRem(16),
    lineHeight: sunoTheme.typography.pxToRem(22),
    letterSpacing: 0,
  },
  body3: {
    fontFamily: "LatoRegular, sans-serif",
    fontSize: sunoTheme.typography.pxToRem(18),
    lineHeight: sunoTheme.typography.pxToRem(26),
    letterSpacing: 0,
  },
  caption: {
    fontFamily: "LatoRegular, sans-serif",
    fontSize: sunoTheme.typography.pxToRem(12),
    lineHeight: sunoTheme.typography.pxToRem(16),
    letterSpacing: 0,
  },
  button: {
    fontFamily: "LatoRegular, sans-serif",
    fontWeight: 700,
    fontSize: sunoTheme.typography.pxToRem(16),
    lineHeight: sunoTheme.typography.pxToRem(22),
    letterSpacing: 0,
  },
});

export default getSunoTypography;

import InternalErrorPage from "../error";
import { grey } from "@mui/material/colors";
import CircularProgressLoader from "../loading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MainContentForms from "./main-content-forms.component";
import MainContentHeader from "./main-content-header.component";
import { useIntakeFormSubmissions } from "../../services/hooks/intake-form/use-intake-form.hook";
import {
  IntakeFormRequestSubmission,
  IntakeFormSubmissionStatus,
  OnboardingRequest,
} from "../../services/types/intake-form-request.type";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { Patient } from "../../services/types/patient.type";
import { useMemo } from "react";
import { PatientPracticeType } from "../../services/types/patient-practice.type";

interface IntakeFormResponse {
  data: any;
  isLoading: boolean;
  isError?: boolean;
}

interface LayoutMainContentProps {
  patient: Patient;
  practice: PatientPracticeType | undefined;
  submittedForms: string[];
}

export default function LayoutMainContent({
  patient,
  practice,
  submittedForms,
}: LayoutMainContentProps) {
  const {
    data = [],
    isLoading,
    isError,
  }: IntakeFormResponse = useIntakeFormSubmissions();

  const intakeFormData = useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    if (submittedForms.length) {
      return data.map((submissionRequest) => {
        const updatedSubmissions =
          submissionRequest.intake_form_request_submissions.map(
            (formRequest) => {
              if (formRequest.status === IntakeFormSubmissionStatus.SUBMITTED) {
                return formRequest;
              }

              if (
                submittedForms.includes(formRequest.intake_form.external_id)
              ) {
                return {
                  ...formRequest,
                  status: IntakeFormSubmissionStatus.SUBMITTED,
                };
              }

              return formRequest;
            },
          );

        return {
          ...submissionRequest,
          intake_form_request_submissions: updatedSubmissions,
        };
      });
    }

    return [...data];
  }, [data, submittedForms]);

  const { fullName = "", email = "" } = patient || {};

  const getSubmittedFormCount = (
    requestedForms: IntakeFormRequestSubmission[] = [],
  ) => {
    return requestedForms.filter(
      (form) => form.status === IntakeFormSubmissionStatus.SUBMITTED,
    ).length;
  };

  if (isLoading) {
    return <CircularProgressLoader />;
  }

  if (isError) {
    <InternalErrorPage />;
  }

  return (
    <Stack
      direction="column"
      className="animate__animated animate__fadeIn"
      width={"100%"}
      sx={{
        p: { md: "0 100px" },
      }}
    >
      <MainContentHeader patient={patient} practice={practice} />
      {intakeFormData.map((submissionRequest: OnboardingRequest, index) => {
        return (
          <Accordion
            key={submissionRequest.id}
            defaultExpanded={index === 0}
            sx={{
              backgroundColor: grey[50],
              mt: index === 0 ? "20px" : "",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="submission-forms-content"
            >
              <Stack
                sx={{
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                }}
              >
                <Stack direction="column">
                  <Typography>Form Submission Request</Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: grey[600],
                    }}
                  >
                    Requested on&nbsp;
                    {new Date(
                      submissionRequest.created_at || "",
                    ).toDateString()}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: grey[600],
                    fontStyle: "italic",
                  }}
                >
                  {`Completed ${getSubmittedFormCount(
                    submissionRequest.intake_form_request_submissions,
                  )} of ${submissionRequest?.intake_form_request_submissions?.length ?? 0}`}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <MainContentForms
                requestId={submissionRequest.id}
                fullName={fullName}
                email={email}
                practice={practice}
                intakeFormRequests={
                  submissionRequest.intake_form_request_submissions
                }
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Stack>
  );
}

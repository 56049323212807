export interface OnboardingRequest {
  id: number;
  updated_at?: string;
  created_at?: string;
  // Setup Appointment types once available. Variables can be imported as setup
  appointment?: string | null;
  intake_form_request_submissions?: IntakeFormRequestSubmission[];
}

export interface IntakeForm {
  id: number;
  external_id: string;
  title: string;
  status: number;
  archived: boolean;
  external_created_at: string;
  external_updated_at: string;
  updated_at: string;
  created_at: string;
  discarded_at: null;
}

export interface IntakeFormRequestSubmission {
  id: number;
  intake_form: IntakeForm;
  status: string;
  external_submission_id: null;
  external_created_at: null;
  external_updated_at: null;
  created_at: string;
  updated_at: string;
  intake_form_request: number;
}

export enum IntakeFormSubmissionStatus {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
}

import React, { createContext, useContext, useState, useEffect } from "react";
import { useSessionStorage } from "../../utils/local-storage";

interface FormData {
  formId: string;
  requestId: number;
  email?: string;
  fullName?: string;
  practiceName?: string;
}

const FormContext = createContext<{
  formData: FormData;
  setFormData: React.Dispatch<FormData>;
} | null>(null);

export const FormProvider = ({ children }) => {
  const { getData, updateData } = useSessionStorage("formData");

  const [formData, setFormData] = useState(getData(null));

  useEffect(() => {
    updateData(formData);
  }, [formData, updateData]);

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);

export const primary = {
  50: "#AEF9F5",
  100: "#5DF4EA",
  200: "#20EFE3",
  300: "#0CACA3",
  400: "#087069",
  500: "#054743",
  600: "#043532",
  700: "#032C2A",
  800: "#021B19",
  900: "#010908",
};

export const neutral = {
  50: "#F7F7F7",
  100: "#E8E8E8",
  200: "#DFE1E0",
  300: "#D7D9D8",
  400: "#CFCFCF",
  500: "#BFC2C1",
  600: "#8E9391",
  700: "#7A807E",
  800: "#262727",
  900: "#090A0A",
};

export const success = {
  50: "#ECFDF5",
  100: "#D1FAE5",
  200: "#A7F3D0",
  300: "#6EE7B7",
  400: "#34D399",
  500: "#10B981",
  600: "#059669",
  700: "#047857",
  800: "#065F46",
  900: "#064E3B",
};

export const error = {
  50: "#FEF2F2",
  100: "#FEE2E2",
  200: "#FECACA",
  300: "#FCA5A5",
  400: "#F87171",
  500: "#EF4444",
  600: "#DC2626",
  700: "#B91C1C",
  800: "#991B1B",
  900: "#7F1D1D",
};

export const misc = {
  lightMutedPurple: "#F5F6F8",
  mutedPurple: "#f0f2ff",
  grayAsphalt: "#505F7A",
  blackAsphalt: "#0F1010",
  purpleText: "#762FBD",
  linkColor: "#551A8B",
  lightYellow: "#fffde7",
  darkYellow: "#fcc02a",
  lightGreen: "#daefe9",
  darkGreen: "#9ac4bb",
  default: "#ebebeb",
  warningText: "#e65100",
};

import { primary } from "../colors";

export const getCheckboxComponents = () => ({
  MuiCheckbox: {
    styleOverrides: {
      root: {
        "& .MuiSvgIcon-root": {
          fontSize: 24,
          color: primary[500],
        },
      },
    },
  },
});

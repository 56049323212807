import { Navigate, Outlet } from "react-router-dom";

interface PublicRouteProps {
  authenticated: boolean;
}

export default function PublicRoute(props: PublicRouteProps) {
  const { authenticated } = props;
  // If already authenticated redirect to forms or previous URL from all incoming public URLs
  if (authenticated) {
    return <Navigate to="forms" />;
  }
  return <Outlet />;
}

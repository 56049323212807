import {
  Alert,
  Box,
  Checkbox,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./form-dashboard-preview.styles.css";
import { IntakeForm } from "../../services/types/intake-form-request.type";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { useFormContext } from "../../services/contexts/FormContext";

interface FormDashboardPreviewProps {
  intakeForm: IntakeForm;
  email?: string;
  fullName?: string;
  practiceName?: string;
  requestId: number;
  isSubmitted: boolean;
}

export default function FormDashboardPreview({
  intakeForm,
  email,
  fullName,
  practiceName,
  requestId,
  isSubmitted,
}: FormDashboardPreviewProps) {
  const navigate = useNavigate();
  const formContext = useFormContext();

  const [errorSnackBarIsOpened, setErrorSnackBarIsOpened] = useState(false);

  const openErrorSnackBar = () => {
    setErrorSnackBarIsOpened(true);
  };

  const closeErrorSnackBar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorSnackBarIsOpened(false);
  };

  const handleFormClick = () => {
    const formId = intakeForm.external_id;

    if (formId && requestId && fullName && practiceName) {
      formContext?.setFormData({
        formId,
        requestId,
        email,
        fullName,
        practiceName,
      });

      if (!isSubmitted) {
        navigate(intakeForm.external_id);
      }
    } else {
      openErrorSnackBar();
    }
  };

  return (
    <>
      <Snackbar
        open={errorSnackBarIsOpened}
        autoHideDuration={6000}
        onClose={closeErrorSnackBar}
        message=""
      >
        <Alert severity="error">
          Something went wrong. Please, try to reload page or re-login.
        </Alert>
      </Snackbar>
      <Box
        sx={{
          overflow: "hidden",
          transition: "all 0.3s",
          opacity: 1,
          width: "300px%",
          flexGrow: "1",
          padding: "5px 14px 5px 5px",
          borderRadius: "5px",
          border: "1px solid #D3D3D3",
          backgroundColor: "white",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          ...(isSubmitted
            ? {
                borderColor: grey[300],
              }
            : {
                borderColor: grey[50],
                "&:hover": {
                  cursor: "pointer",
                  transform: "scale(1.01)",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                },
              }),
        }}
        onClick={handleFormClick}
        className="form-preview animate__animated animate__fadeIn animate__delay-1s"
      >
        <Stack direction="row" alignItems="center">
          <Checkbox disabled checked={isSubmitted} />
          <Typography variant="body1">{intakeForm.title}</Typography>
        </Stack>
      </Box>
    </>
  );
}

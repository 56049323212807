import { Theme } from "@mui/material/styles";
import { neutral } from "../colors";

export const getInputComponents = (sunoTheme: Theme) => ({
  MuiInputBase: {
    styleOverrides: {
      input: {
        fontFamily: "LatoRegular, sans-serif",
        fontSize: sunoTheme.typography.pxToRem(16),
        lineHeight: sunoTheme.typography.pxToRem(22),
        letterSpacing: 0,
        "&::placeholder": {
          color: neutral[600],
          opacity: 1,
        },
      },
    },
  },
});

import { datadogRum } from "@datadog/browser-rum";

export default function setupDatadog() {
  if (process.env.REACT_APP_ENV !== "local") {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID!,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN!,
      site: process.env.REACT_APP_DATADOG_SITE!,
      service: `suno-patient-portal-${process.env.REACT_APP_ENV}`,
      env: process.env.REACT_APP_ENV,
      //  env: 'production',
      //  version: '1.0.0',
      allowedTracingUrls: [process.env.REACT_APP_SUNO_API!],
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100, // if not included, the default is 100
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: "allow",
    });
    datadogRum.startSessionReplayRecording();
  }
}

export default function useLocalStorage(
  serviceName: string,
  storageType: "localStorage" | "sessionStorage" = "localStorage",
) {
  const storage =
    storageType === "localStorage" ? localStorage : sessionStorage;

  return {
    init: (initialData: any, fallbackData: any) => {
      if (Boolean(storage.getItem(serviceName))) {
        storage.removeItem(serviceName);
      }
      storage.setItem(serviceName, JSON.stringify(initialData || fallbackData));
      return true;
    },
    getData: (fallbackData: any) =>
      JSON.parse(storage.getItem(serviceName) || JSON.stringify(fallbackData)),
    updateData: (data: any) =>
      storage.setItem(serviceName, JSON.stringify(data)),
    clear: () => storage.removeItem(serviceName),
  };
}

export const useSessionStorage = (serviceName: string) => {
  return useLocalStorage(serviceName, "sessionStorage");
};

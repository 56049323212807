import { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SideMenuItem from "./side-menu-item.component";
import { Button, useMediaQuery } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useMatch, useResolvedPath } from "react-router-dom";
import { PatientPracticeType } from "../../services/types/patient-practice.type";

const getMenu = (practiceName: string = "") => [
  {
    to: practiceName ? `/${practiceName.replaceAll(" ", "-")}/forms` : "",
    primary: "Forms",
    Icon: DashboardIcon,
    isFallbackPath: true,
  },
];

interface LayoutSideMenuProps {
  practice: PatientPracticeType | undefined;
}

export default function LayoutSideMenu({ practice }: LayoutSideMenuProps) {
  const theme = useTheme();
  let resolved = useResolvedPath("/");
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const isDefaultPath = Boolean(
    useMatch({ path: resolved.pathname, end: true }),
  );

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          left: 0,
          height: "auto",
          width: "100px",
          minWidth: "100px",
          minHeight: "100vh",
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          transition: "all 0.5s ease-in-out",
          background: theme.palette.sideMenu.main,
          "&::-webkit-scrollbar": {
            display: "none",
          },
          ...(isMobileDevice && {
            position: "absolute",
            left: "-120px",
            zIndex: 2,
            transition: "all 0.5s ease-in-out",
          }),
          ...(isMenuOpen && {
            left: 0,
          }),
        }}
        className="animate__animated animate__fadeInLeft"
        data-testid="layout-side-menu"
      >
        <List>
          {getMenu(practice?.name).map(
            ({ to, primary, Icon, isFallbackPath }, index) => (
              <SideMenuItem
                to={to}
                Icon={Icon}
                primary={primary}
                key={`${index}_${to}`}
                isDefaultPath={isDefaultPath}
                isFallbackPath={isFallbackPath}
              />
            ),
          )}
        </List>
      </Stack>
      {isMobileDevice && (
        <Box
          sx={{
            left: 0,
            zIndex: 2,
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            ...(isMenuOpen && {
              left: "90px",
            }),
          }}
        >
          {isMenuOpen ? (
            <Button
              className="animate__animated animate__fadeIn animate__delay-1s"
              onClick={() => setIsMenuOpen(false)}
            >
              <CloseIcon />
            </Button>
          ) : (
            <Button
              className="animate__animated animate__fadeIn"
              onClick={() => setIsMenuOpen(true)}
            >
              <MenuIcon />
            </Button>
          )}
        </Box>
      )}
    </>
  );
}

import useFetch from "../../../utils/fetch-data";
import { useSessionStorage } from "../../../utils/local-storage";
import { PatientPracticeResponse } from "../../types/patient-practice.type";

const PRACTICE_INFO_API_PATH = "/patient-portal/practice/";

export function usePracticeData(practiceName: string) {
  const localAuthSession = useSessionStorage("authToken");
  const { isLoading, data, isError }: PatientPracticeResponse = useFetch({
    method: "GET",
    url: `${process.env.REACT_APP_SUNO_API}${PRACTICE_INFO_API_PATH}${
      practiceName + "/"
    }`,
    token: localAuthSession.getData(null),
  });

  return {
    data,
    isLoading,
    isError,
  };
}

import { useCallback, useEffect, useState } from "react";
import SUNO_LOGO from "../../assets/images/logo.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { EMAIL_REGEX, TEN_DIGIT_PHONE_REGEX } from "../../utils/regex";
import { Alert, TextField, Typography } from "@mui/material";

import "./login-form.styles.css";
import { usePracticeData } from "../../services/hooks/patient-practice/use-practice-data.hook";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getIsoDateFormat } from "../../utils/date";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

interface Props {
  isError: boolean;
  isLoading: boolean;
  auth: (props: {
    email: string;
    password: string;
    practice_id: number;
  }) => Promise<{ data: { auth_token: any }; isError: boolean }>;
}

export default function Login({ auth, isError, isLoading }: Props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [email, setEmail] = useState(
    searchParams.get("username") || searchParams.get("email") || "",
  );
  const [isFormInvalid, setIsFormInvalid] = useState(true);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isDobInvalid, setIsDobInvalid] = useState(false);

  const { practiceName = "" } = useParams();
  const { data: practiceData, isError: isPracticeNameError } =
    usePracticeData(practiceName);

  const validateForm = useCallback(() => {
    const isEmailValid = Boolean(
      email.length &&
        (email.match(EMAIL_REGEX) || email.match(TEN_DIGIT_PHONE_REGEX)),
    );

    const isDobValid = dateOfBirth?.toString() !== "Invalid Date";

    setIsDobInvalid(!isDobValid);
    setIsEmailInvalid(!isEmailValid);
    return isEmailValid && isDobValid;
  }, [email, dateOfBirth]);

  const handleSubmit = useCallback(() => {
    if (!validateForm() || !practiceData) return;

    auth({
      email,
      password: getIsoDateFormat(dateOfBirth?.toString()),
      practice_id: practiceData?.id || -1,
    });
  }, [auth, email, dateOfBirth, validateForm, practiceData]);

  const handleDateBlur = () => {
    setIsDobInvalid(dateOfBirth?.toString() === "Invalid Date");
  };

  useEffect(() => {
    if (isPracticeNameError) {
      navigate("/");
    }
  }, [isPracticeNameError, navigate]);

  useEffect(() => {
    setIsFormInvalid(isEmailInvalid || isDobInvalid);
  }, [isEmailInvalid, isDobInvalid]);

  return (
    <div className="login-container">
      {practiceData ? (
        <Stack
          alignItems="center"
          margin="auto"
          borderRadius={"8px"}
          maxWidth="420px"
          sx={{ background: "white" }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            p={5}
            boxSizing={"border-box"}
          >
            <Box
              component={"img"}
              src={practiceData?.logo}
              alt="practice logo"
              maxWidth={"100%"}
              height={"auto"}
              borderRadius="4px"
            />
          </Stack>

          <Stack
            gap={3}
            className="login-form-container animate__animated animate__fadeIn animate__slow"
            sx={{ borderTop: "solid 1px #eeeeee" }}
          >
            <Typography fontWeight={400} variant="h5">
              Sign&nbsp;in to&nbsp;your Hearing&nbsp;Portal
            </Typography>
            {isError && (
              <Alert
                severity="error"
                sx={{
                  justifyContent: "center",
                }}
                className="animate__animated animate__fadeIn animate__slow"
              >
                Invalid Credentials. Please try again.
              </Alert>
            )}
            <TextField
              required
              fullWidth
              value={email}
              id="email-input"
              variant="standard"
              error={isEmailInvalid}
              label="Email Address or Phone"
              disabled={!!searchParams.get("email")}
              onChange={(e) => setEmail(e.target.value)}
              helperText={isEmailInvalid ? "Email is Invalid" : ""}
              onBlur={(e) =>
                e.target.value.match(EMAIL_REGEX) ||
                e.target.value.match(TEN_DIGIT_PHONE_REGEX)
                  ? setIsEmailInvalid(false)
                  : setIsEmailInvalid(true)
              }
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dateOfBirth}
                label="Date of Birth"
                slotProps={{
                  textField: {
                    variant: "standard",
                    error: isDobInvalid,
                    onBlur: handleDateBlur,
                    helperText: isDobInvalid ? "Date of Birth is Invalid" : "",
                  },
                }}
                onChange={(newValue) => setDateOfBirth(newValue)}
              />
            </LocalizationProvider>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              onClick={handleSubmit}
              disabled={isFormInvalid}
            >
              Sign In
            </LoadingButton>
          </Stack>
        </Stack>
      ) : (
        <CircularProgress sx={{ margin: "auto" }} />
      )}
      <footer className="page-footer">
        powered by <img width="15%" src={SUNO_LOGO} alt="suno logo" />
      </footer>
    </div>
  );
}

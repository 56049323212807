import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import { Box, SvgIconTypeMap, useTheme } from "@mui/material";
import SideMenuItemContent from "./side-menu-item-content.component";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type SideMenuItemProps = {
  isDefaultPath: boolean;
  to: string;
  primary: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  divider?: boolean;
  isFallbackPath: boolean;
};
export default function SideMenuItem({
  to,
  Icon,
  primary,
  divider,
  isDefaultPath,
  isFallbackPath,
}: SideMenuItemProps) {
  const theme = useTheme();

  return (
    <ListItem
      disablePadding
      divider={divider}
      sx={{ borderColor: theme.palette.neutral[500] }}
    >
      <NavLink
        to={to}
        style={{ display: "flex", flex: 1, textDecoration: "none" }}
      >
        {({ isActive }) => (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "8px",
              px: 1.5,
              py: 2.5,
              position: "relative",
              "&::before": {
                content: "''",
                display:
                  isActive || (isFallbackPath && isDefaultPath)
                    ? "block"
                    : "none",
                width: "calc(100% - 8px)",
                height: "calc(100% - 8px)",
                position: "absolute",
                left: "4px",
                top: "4px",
                border: "3px solid #fff",
                boxSizing: "border-box",
                borderRadius: "17px",
              },
            }}
          >
            <SideMenuItemContent
              isActive={isActive}
              primary={primary}
              Icon={Icon}
            />
          </Box>
        )}
      </NavLink>
    </ListItem>
  );
}

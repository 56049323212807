import { Fab, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import JotFormReact from "jotform-react";
import "./embedded-forms.styles.css";
import { useCallback } from "react";
import { useFormContext } from "../../services/contexts/FormContext";

const formIframeStyles = {
  minWidth: "100%",
  maxWidth: "100%",
  border: "none",
  height: "100vh",
  minHeight: "100vh",
};

interface EmbeddedFormsProps {
  onSubmit: (formId: string) => void;
}

export default function EmbeddedForms({ onSubmit }: EmbeddedFormsProps) {
  const navigate = useNavigate();
  const { practiceName } = useParams();
  const formContext = useFormContext();
  const formData = formContext?.formData;

  const navigateToDashboard = useCallback(() => {
    navigate(`/${practiceName}/`);
  }, [practiceName, navigate]);

  const handleSubmit = useCallback(() => {
    formData?.formId && onSubmit(formData?.formId);
    navigateToDashboard();
  }, [navigateToDashboard, formData?.formId, onSubmit]);

  if (
    !formData?.formId ||
    !formData?.requestId ||
    !formData?.fullName ||
    !formData?.practiceName
  ) {
    navigateToDashboard();

    return null;
  }

  const formUrl = `https://form.jotform.com/${formData?.formId}?requestId=${formData?.requestId}&email=${
    formData?.email ? encodeURIComponent(formData?.email) : ""
  }&full_name=${encodeURIComponent(
    formData?.fullName,
  )}&practice_name=${encodeURIComponent(formData?.practiceName)}`;

  return (
    <>
      <Tooltip title="Go to Dashboard" arrow>
        <Fab id="back-to-dashboard-button" onClick={navigateToDashboard}>
          <ArrowBackIcon />
        </Fab>
      </Tooltip>
      <JotFormReact
        scrolling="yes"
        style={formIframeStyles}
        formID={formData?.formId}
        onSubmit={handleSubmit}
        title={`Title-${formData?.formId}`}
        id={`JotFormIFrame-${formData?.formId}`}
        formURL={formUrl}
      />
    </>
  );
}

import { Box, Stack, Typography, useTheme } from "@mui/material";
import SUNO_LOGO from "../../assets/images/logo.svg";

interface LandingPageProps {
  authenticated: boolean;
}

export default function LandingPage({ authenticated }: LandingPageProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        background: theme.palette.sideMenu.main,
      }}
    >
      <Stack sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <Typography
          variant="h2"
          color="white"
          sx={{ display: "flex", mt: "100px" }}
        >
          Patient Portal
        </Typography>
        <Typography variant="subtitle1" color="white" sx={{ display: "flex" }}>
          Please {authenticated ? "use" : "login with"} the link sent to you
        </Typography>
        <footer className="page-footer">
          powered by <img width="25%" src={SUNO_LOGO} alt="suno logo" />
        </footer>
      </Stack>
    </Box>
  );
}

import { Navigate, Outlet, useParams } from "react-router-dom";

interface ProtectedRouteProps {
  authenticated: boolean;
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { authenticated } = props;
  const { practiceName } = useParams();

  if (!authenticated) {
    return <Navigate to={`/${practiceName}/login`} />;
  }
  return <Outlet />;
}

import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { useCallback, useState } from "react";
import Login from "./pages/login";
import { sunoTheme } from "./app/themes";
import { ThemeProvider } from "@mui/material";
import LandingPage from "./pages/landing-page";
import EmbeddedForms from "./pages/embedded-forms";
import { ProtectedRoute, PublicRoute } from "./routes";
import PatientFormsDashboard from "./pages/forms-dashboard";
import useAuthentication from "./services/hooks/login/use-authentication.hook";
import { FormProvider } from "./services/contexts/FormContext";

import "animate.css";

function App() {
  const { authenticated, auth, isError, isLoading } = useAuthentication();

  const [submittedForms, setSubmittedForms] = useState<string[]>([]);

  const pushFormToSubmitted = useCallback(
    (formId: string) => {
      setSubmittedForms((prev) => [...prev, formId]);
    },
    [setSubmittedForms],
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={<LandingPage authenticated={authenticated} />}
        />
        <Route path="/:practiceName">
          <Route
            path=""
            element={
              authenticated ? (
                <Navigate
                  to={{ pathname: "forms", search: window.location.search }}
                />
              ) : (
                <Navigate
                  to={{ pathname: "login", search: window.location.search }}
                />
              )
            }
          />
          <Route element={<PublicRoute authenticated={authenticated} />}>
            <Route
              path="login"
              element={
                <Login auth={auth} isError={isError} isLoading={isLoading} />
              }
            />
          </Route>
          <Route element={<ProtectedRoute authenticated={authenticated} />}>
            <Route
              path="forms"
              element={
                <PatientFormsDashboard submittedForms={submittedForms} />
              }
            />
            <Route
              path="forms/:formID"
              element={<EmbeddedForms onSubmit={pushFormToSubmitted} />}
            />
          </Route>
          <Route
            path="*"
            element={<LandingPage authenticated={authenticated} />}
          />
        </Route>
      </>,
    ),
  );

  return (
    <ThemeProvider theme={sunoTheme}>
      <FormProvider>
        <RouterProvider router={router} />
      </FormProvider>
    </ThemeProvider>
  );
}

export default App;
